<template>
  <div class="text-center w-100 mb-12">
    <template v-if="!loading && !staff.is_auto_login">
      <TTBtn
        fab
        depressed
        color="white"
        elevation="1"
        large
        data-test="aside-right-btn-help"
        class="mb-2 v-btn--aside"
        @click="$root.$emit('editUser')"
      >
        <VIcon
          color="tt-black"
          size="19"
        >
          fal fa-user-edit
        </VIcon>
      </TTBtn>
      <div class="tt-text-caption tt-black--text text--lighten-2">
        Редакт.<br>
        профиль
      </div>
    </template>
  </div>
</template>

<script>
import * as names from '@/plugins/vue-router/constants';

export default {
  name: 'AppStaffInfoAsideRight',
  data: () => ({
    names,
    staff: {},
    loading: false,
  }),
  watch: {
    $route: {
      handler() {
        this.loading = true;
        this.$repositories.staff.list({
          data: {
            filter: {
              id: [this.$route.params.id],
            },
          },
        })
          .then((r) => {
            const { data } = r.data;
            [this.staff] = data;
          })
          .catch((e) => {
            console.warn(e);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      immediate: true,
    },
  },
};
</script>

<style>
/* stylelint-ignore no-empty-source */
</style>
